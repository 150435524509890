import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import api from 'services/api/api';
import { logout } from 'ducks/auth/email';
import { requestAuthorizationStatus, requestLogoutStatus } from 'ducks/user';

const useAuthorizationDetector = (requestAuthorizationStatus = true) => {
  const [prevValue, setPrevValue] = useState(true);

  useEffect(() => {
    setPrevValue(requestAuthorizationStatus);
  }, [requestAuthorizationStatus]);

  return prevValue && requestAuthorizationStatus === false;
};

function useAccessToken() {
  const [token, setToken] = useState(api.getToken());

  useEffect(() => {
    function handler() {
      const latestToken = api.getToken();
      if (token !== latestToken) {
        setToken(latestToken);
      }
    }
    window.addEventListener('storage', handler);
    return () => window.removeEventListener('storage', handler);
  }, [token]);

  const updateToken = useCallback((value) => {
    api.setToken(value);
    setToken(value);
  }, []);
  return [token, updateToken];
}

function parseTokenExpiration(token) {
  if (token?.startsWith('Bearer')) {
    try {
      const [_scheme, jwt] = token.split(' ');
      const [_header, payload] = jwt.split('.');
      return moment.unix(JSON.parse(atob(payload)).exp);
    } catch {
      return null;
    }
  }
  return null;
}

function useRefreshAccessToken({ onFail, onLogout }) {
  const [accessToken, setToken] = useAccessToken();

  const { getAccessTokenSilently } = useAuth0();
  const timeoutRef = useRef();

  async function refreshAccessToken() {
    try {
      const newToken = await getAccessTokenSilently();
      setToken(`Bearer ${newToken}`);
    } catch (e) {
      if (
        e.message === 'Unknown or invalid refresh token.' ||
        e.message.includes('Missing Refresh Token')
      ) {
        onLogout();
      }
      throw e;
    }
  }

  useEffect(() => {
    const exp = parseTokenExpiration(accessToken);
    if (exp) {
      const threshold = moment(exp).subtract(30, 'seconds');
      const delay = threshold.diff(moment(), 'milliseconds');
      timeoutRef.current = setTimeout(() => {
        refreshAccessToken().catch(onFail);
      }, delay);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [accessToken]);

  return {
    refreshAccessToken,
  };
}

const AuthProvider = ({
  children,
  legacyLogout,
  requestLogout,
  requestLogoutFn,
  authorizationStatus,
  requestAuthorizationStatusFn,
}) => {
  const { logout: auth0Logout } = useAuth0();
  const lostAccess = useAuthorizationDetector(authorizationStatus);
  const { refreshAccessToken } = useRefreshAccessToken({
    onFail: redirectToLogin,
    onLogout: requestLogoutFn,
  });

  async function redirectToLogin() {
    window.location.href = `/login`;
  }

  useEffect(() => {
    if (lostAccess) {
      refreshAccessToken()
        .then(() => {
          requestAuthorizationStatusFn(true);
          window.location.reload();
        })
        .catch(() => redirectToLogin());
    }
  }, [lostAccess]);

  useEffect(() => {
    if (requestLogout == true) {
      handleLogout();
    }
  }, [requestLogout]);

  const handleLogout = () => {
    if (api.hasAuth0Token()) {
      return auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      }).then(() => {
        return legacyLogout();
      });
    } else {
      return legacyLogout();
    }
  };

  return <div>{children}</div>;
};

export default connect(
  (state) => {
    return {
      requestLogout: state.user.requestLogout,
      authorizationStatus: state.user.authorizationStatus,
    };
  },
  (dispatch) => {
    const legacyLogout = () => dispatch(logout());
    const requestLogoutFn = () => dispatch(requestLogoutStatus(true));
    const requestAuthorizationStatusFn = (value) => dispatch(requestAuthorizationStatus(value));
    return {
      legacyLogout,
      requestLogoutFn,
      requestAuthorizationStatusFn,
    };
  }
)(AuthProvider);
